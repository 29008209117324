// This file contains GLOBAL overrides for the currently active Material Design theme.
//
// As per Angular Material's theming guide-lines the outside styling of Material components is not supported
// and this should be handled as an escape-hatch instead of a default go-to method.

// This is the default style for all opened dialog containers.
// The app dialog class is added to opened dialogs via the open method `panelClass` property.
.app-dialog {
  padding: 0;
}

// The MDC version of input contains a placeholder for error messages but this container has no bottom margin.
// We always want to space our error message container from the next element.
.mat-mdc-form-field-subscript-wrapper {
  margin-bottom: 6px;
}

// The density option seems to mess up text alignment in the input.
// This is mostly related to changed text alignment to baseline instead of center.
// TODO: We need to sometimes in future check if this is needed in latest Material release
.mat-mdc-form-field-infix {
  display: flex;
  align-items: center;
  line-height: normal;
}

.mat-mdc-paginator {
  // The Material table paginator has it's text color hard-coded and the default black value used
  // draws too much attention so we fallback using a lighter color.
  color: #0000008a;

  // The default pagination container takes up too much vertical space since they switch to MDC components.
  // To remedy this we set the default height to auto to allow the browser to select the minimum height based on content.
  & .mat-mdc-paginator-container {
    min-height: auto;
  }
}

mat-hint {
  color: #0009;
}

.mat-mdc-header-cell.mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 500;
  color: #0000008a;
}

.mat-mdc-tab-list {
  flex-grow: 0 !important;
}

.mat-mdc-paginator .mat-mdc-paginator-container {
}

.mat-mdc-paginator-outer-container {
  height: 48px;
}

.mdc-dialog__title {
  padding: 0 !important;
}

.mat-mdc-dialog-container .mdc-dialog__title {
  padding: 0 24px 0 24px !important;
}

.mdc-dialog .mdc-dialog__content {
  padding: 12px 24px 0 24px !important;
}

.ngx-timepicker-control__arrow {
  height: 15px;
}

.ngx-material-timepicker-toggle {
  margin-top: 5px;
}

// This is a necessary hack, because the timepicker's z-index is 999 where the material dialog's is 1000.
// For more info see: https://github.com/Agranom/ngx-material-timepicker/issues/244
.timepicker-overlay {
  z-index: 1000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 1000000 !important;
}

.mat-mdc-text-field-wrapper {
  flex: unset !important;
}

ngx-timepicker-field {
  height: 32px;
}

.ngx-timepicker-control__input {
  font-size: 14px !important;
}