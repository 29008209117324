$error-100: #ffcdd2;
$error: #b71c1c;

$warn-100: rgb(252, 230, 189);
$warn: rgb(255, 115, 0);

$info-100: #cfe2ff;
$info: #084298;

$ui-unset-default-value: #adadad;

/* Light header color when we want to focus on the data.*/
$ui-light-header: rgba(0,0,0,.6);

/* Color of even rows of tables in light setting */
$ui-table-row-even: #eceff173;

/* Background color on the page. */
$background-color: #fafafa;