@use 'material-theme';
@use 'material-overrides';
@import 'colors';

/* You can add global styles to this file, and also import other style files */
html,
body {
  margin: 0;
  padding: 0;
}
html,
body,
app-root {
  height: 100%;
  width: 100%;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.spacer {
  flex-grow: 1;
}

img {
  max-width: 100%;
  height: auto;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

// Set proper settings for dialogs, this allows a sane max default, but also
// fills the entire screen on mobiles.
.cdk-overlay-pane {
  max-width: none !important;
}

// This selector is necessary for the tab-body to fill the remaining height of the screen,
// which is necessary in order for us to place the paginator at the bottom on the `portfolio`
// route plants tab.
.mat-mdc-tab-body-wrapper {
  flex-grow: 1;
}

.error-box {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: $error-100;
  padding: 1rem;
  border-radius: 5px;
  box-sizing: border-box;

  p {
    color: $error;
    margin: 0;
    margin-left: 0.8rem;
  }
}


.info-box {
  width: 100%;
  display: flex;
  align-items: center;
  // TODO: Add these colors and styles correctly
  background-color: #cfe2ff;
  border-color: #b6d4fe;
  padding: 1rem;
  border-radius: 5px;
  box-sizing: border-box;
  margin-bottom: 8px;

  p {
    color: #084298;
    margin: 0;
    margin-left: 0.8rem;
  }
}


/*
 * General styles for tables.
*/
table[mat-table] {
  width: 100%;
  border: 1px solid #eee;
  border-bottom: none;

  tr:nth-child(even) {
    background-color: $ui-table-row-even;
  }

  tr:hover {
    background-color: inherit;
  }

  tr:nth-child(even):hover {
    background-color: $ui-table-row-even !important;
  }
}

// Hide the ReCaptcha badge on the right.
.grecaptcha-badge { 
  visibility: hidden;
}

.g-cursor-pointer {
  cursor: pointer;
}

.tooltip--multiline {
  white-space: pre-line;
}

.mat-dialog-subtitle {
  padding: 0 24px 0 24px !important;
}

.button-with-spinner {
  display: flex;
  align-items: center;
}

button mat-spinner {
  display: inline-block;
  margin-left: 8px;
}

.mat-mdc-dialog-actions {
  padding: 0px 24px !important;
}